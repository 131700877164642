<template>
  <el-dialog
    :title="$t('complaint')"
    :visible.sync="$store.state.complaint.complaintImDialogVisible"
    custom-class="complaintIMPopover"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    center
  >
    <!-- 投诉资料上传 -->
    <div class="complaint-upload">
      <div class="upload-title">
        <div>投诉证据(必填)</div>
        <div class="pic-limit">0/9</div>
      </div>
      <div class="upload-wrap">
        <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog
          :visible.sync="dialogVisible2"
          :modal="false"
          :destroy-on-close="true"
        >
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
    </div>

    <!-- 分割线 -->
    <div class="split-ine"></div>
    <!-- 投诉原因 -->
    <div class="complaint-reason">
      <div class="reason-title">
        {{ $t("complaintReason") }}({{ $t("required") }})
      </div>
      <div class="reason-list">
        <el-radio
          v-model="radio"
          :label="item.itemId"
          v-for="item in reasonList"
          :key="item.itemId"
          >{{ item.itemVal }}</el-radio
        >
        <el-input
          v-if="radio == -1"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 10 }"
          :placeholder="$t('pleaseEnterContent')"
          v-model="textarea"
          resize="none"
          maxlength="200"
          show-word-limit
        >
        </el-input>
      </div>
      <el-button
        class="complaint-btn"
        icon="el-icon-check"
        :disabled="disabled"
        :loading="loading"
        @click="submit"
      ></el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      type: null,
      userInfo: null,
      groupInfo: null,
      radio: null,
      textarea: "",
      postDetails: null,
      content: null,
      type: null,
      comment: null,
      loading: false,
      dialogImageUrl: "",
      dialogVisible2: false,
    };
  },
  props: {
    reasonList: {
      type: Array,
      default: [],
    },
  },
  computed: {
    disabled: function() {
      if (!this.radio || (this.radio == -1 && !this.textarea)) {
        return true;
      } else {
        return false;
      }
    },
    commentContent() {
      return this.reconvert(this.comment.content);
    },
  },
  mounted() {
    // this.userInfo = this.$store.getters.userInfo;
  },
  methods: {
    //取消操作
    dialogVisible() {
      this.$store.commit("setComplaintImDialogVisible", false);
      //   this.clearDate();
    },
    beforeClose() {
      this.dialogVisible();
    },
    submit() {
      this.loading = true;
      this.$emit(
        "confirmComplaint",
        {
          textarea: this.textarea,
          value: this.radio,
        },
        () => {
          this.clearDate();
        }
      );
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible2 = true;
    },
  },
};
</script>

<style lang="scss">
.ellipsis-2 {
  overflow: hidden; //超出文本隐藏
  text-overflow: ellipsis; ///超出部分省略号显示
  display: -webkit-box; //弹性盒模型
  -webkit-box-orient: vertical; //上下垂直
  -webkit-line-clamp: 2;
}

.complaintIMPopover {
  width: 600px !important;
  height: auto !important;
  padding: 0 0 30px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /*修改滚动条样式*/
  div::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    display: none;
  }
  // max-height: 60%;
  user-select: none;
  // 证据上传部分
  .complaint-upload {
    padding: 0 40px;
    .upload-title {
      font-size: 16px;
      font-weight: 700;
      color: #1a051d;
      display: flex;
      justify-content: space-between;
      .pic-limit {
        font-size: 15px;
        color: #999;
        font-weight: 400;
      }
    }
    .upload-wrap {
      padding: 20px 0;
      .el-upload--picture-card {
        width: 90px;
        height: 90px;
        line-height: 90px;
      }

      .el-upload-list--picture-card .el-upload-list__item {
        width: 90px;
        height: 90px;
        line-height: 90px;
      }
    }
  }
  .split-ine {
    height: 10px;
    background: #f7f7f7;
  }
  .complaint-reason {
    flex: 1;
    display: flex;
    flex-direction: column;
    // padding: 0 40px;
    .reason-title {
      font-size: 16px;
      font-weight: 700;
      color: #1a051d;
    }
    .reason-list {
      margin: 10px 0 30px 0;
      // height: 224px;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      overflow: auto;
      .el-radio {
        font-size: 14px;
        color: #333333;
        margin-right: 0;
        line-height: 4;
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-direction: row-reverse;
        border-bottom: 1px solid #f6f6f6;
        &:last-child {
          border-bottom: none;
        }

        .el-radio__label {
          padding-left: 0;
        }

        .el-radio__inner {
          width: 18px;
          height: 18px;
          &::after {
            color: #fff;
            width: 0 !important;
            height: 0 !important;
            top: -120% !important;
            left: 8% !important;
            // background: red;
            font-family: "element-icons" !important;
            content: "\E6DA";
          }
        }
      }
      .el-radio__input {
        line-height: 4;
      }
      .el-textarea {
        // padding-left: 4px;
        height: 142px;
        border: none;
        .el-textarea__inner {
          height: 142px !important;
          border: none;
          background: #f7f7f7;
        }
        .el-input__count {
          background: #f7f7f7;
        }
      }
    }
    .complaint-btn {
      width: 150px;
      height: 40px;
      background: #33cc66;
      border-radius: 4px;
      color: #fff;
      align-self: center;
      margin-top: auto;
      font-size: 16px;
      &.el-button.is-disabled {
        background: #cccccc;
        color: #fff;
      }
    }
  }
  .el-dialog__header {
    margin-bottom: 30px;
  }
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
    color: #cccccc;
  }
  .el-dialog--center,
  .el-dialog__body {
    padding: 0 !important;
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
