var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("complaint"),
        visible: _vm.$store.state.complaint.complaintImDialogVisible,
        "custom-class": "complaintIMPopover",
        "before-close": _vm.beforeClose,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: ""
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.complaint,
            "complaintImDialogVisible",
            $event
          )
        }
      }
    },
    [
      _c("div", { staticClass: "complaint-upload" }, [
        _c("div", { staticClass: "upload-title" }, [
          _c("div", [_vm._v("投诉证据(必填)")]),
          _c("div", { staticClass: "pic-limit" }, [_vm._v("0/9")])
        ]),
        _c(
          "div",
          { staticClass: "upload-wrap" },
          [
            _c(
              "el-upload",
              {
                attrs: {
                  action: "https://jsonplaceholder.typicode.com/posts/",
                  "list-type": "picture-card",
                  "on-preview": _vm.handlePictureCardPreview,
                  "on-remove": _vm.handleRemove
                }
              },
              [_c("i", { staticClass: "el-icon-plus" })]
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  visible: _vm.dialogVisible2,
                  modal: false,
                  "destroy-on-close": true
                },
                on: {
                  "update:visible": function($event) {
                    _vm.dialogVisible2 = $event
                  }
                }
              },
              [
                _c("img", {
                  attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
                })
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "split-ine" }),
      _c(
        "div",
        { staticClass: "complaint-reason" },
        [
          _c("div", { staticClass: "reason-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("complaintReason")) +
                "(" +
                _vm._s(_vm.$t("required")) +
                ")\n    "
            )
          ]),
          _c(
            "div",
            { staticClass: "reason-list" },
            [
              _vm._l(_vm.reasonList, function(item) {
                return _c(
                  "el-radio",
                  {
                    key: item.itemId,
                    attrs: { label: item.itemId },
                    model: {
                      value: _vm.radio,
                      callback: function($$v) {
                        _vm.radio = $$v
                      },
                      expression: "radio"
                    }
                  },
                  [_vm._v(_vm._s(item.itemVal))]
                )
              }),
              _vm.radio == -1
                ? _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 10 },
                      placeholder: _vm.$t("pleaseEnterContent"),
                      resize: "none",
                      maxlength: "200",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.textarea,
                      callback: function($$v) {
                        _vm.textarea = $$v
                      },
                      expression: "textarea"
                    }
                  })
                : _vm._e()
            ],
            2
          ),
          _c("el-button", {
            staticClass: "complaint-btn",
            attrs: {
              icon: "el-icon-check",
              disabled: _vm.disabled,
              loading: _vm.loading
            },
            on: { click: _vm.submit }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }